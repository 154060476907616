import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,_vm._b({attrs:{"multiple":_vm.field.multiple,"items":_vm.items,"label":_vm.field.text,"item-text":_vm.textProperty,"rules":_vm.validators,"outlined":"","placeholder":"-","append-icon":"mdi-chevron-down","dense":"","clearable":_vm.field.clearable},on:{"click:clear":_vm.clearValue},scopedSlots:_vm._u([(!_vm.field.multiple)?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.getItemColor(item), fontSize: '14px'})},[_vm._v(" "+_vm._s(_vm.field.itemText ? item[_vm.textProperty] : item)+" ")])]}}:null,(_vm.field.multiple && _vm.field.inputVisibleItems)?{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('MultiselectChoices',{attrs:{"index":index,"label":item[_vm.textProperty],"array-length":_vm.valueTemp ? _vm.valueTemp.length : 0,"visible-items":_vm.field.inputVisibleItems}})]}}:null],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'v-autocomplete',_vm.field.inputAttrs || {},false))}
var staticRenderFns = []

export { render, staticRenderFns }