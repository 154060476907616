<template>
  <v-autocomplete
    v-bind="field.inputAttrs || {}"
    v-model="value"
    :multiple="field.multiple"
    :items="items"
    :label="field.text"
    :item-text="textProperty"
    :rules="validators"
    outlined
    placeholder="-"
    append-icon="mdi-chevron-down"
    dense
    :clearable="field.clearable"
    @click:clear="clearValue"
  >
    <template
      v-if="!field.multiple"
      #item="{ item }"
    >
      <span :style="{ color: getItemColor(item), fontSize: '14px'}">
        {{ field.itemText ? item[textProperty] : item }}
      </span>
    </template>
    <template
      v-if="field.multiple && field.inputVisibleItems"
      #selection="{ item, index }"
    >
      <MultiselectChoices
        :index="index"
        :label="item[textProperty]"
        :array-length="valueTemp ? valueTemp.length : 0"
        :visible-items="field.inputVisibleItems"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import { mapState } from 'vuex'
import get from 'lodash/get'
import MultiselectChoices from '../../Elements/MultiselectChoices.vue'

const optionsColors = {
  'Częściowo zapłacone': 'red',
  'Oczekuje na FV': 'red',
  'Oczekuje na płatność': 'red',
  Zapłacone: 'green',
  'Zapłacone online [Link]': 'green',
  'Zapłacone online [Sklep]': 'green',
  Stare: 'green'
}

export default {
  components: {
    MultiselectChoices
  },
  data() {
    return {
      valueTemp: null
    }
  },
  mixins: [inputMixin],
  mounted() {
    this.valueTemp = this.value
  },
  computed: {
    ...mapState({
      filters: state => state.core.filters
    }),
    items () {
      return this.filters[this.field.options]
    },
    textProperty () {
      return this.field.itemText || 'name'
    },
    value: {
      get () {
        return get(this.data, this.field.value, '')
      },
      set (value) {
        let returnValue = null

        if (this.field.itemValue) {
          const selectedObject = this.items.find((item) => {
            return value === item[this.textProperty]
          })
          returnValue = selectedObject[this.field.itemValue]
        } else {
          returnValue = value
        }
        this.$emit('update', { [this.field.name]: returnValue })
        this.valueTemp = returnValue
      }
    }
  },
  methods: {
    clearValue () {
      this.$nextTick(() => { this.value = null })
    },
    getItemColor(item) {
      return optionsColors[item] || ''
    }
  }
}
</script>
